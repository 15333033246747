import React from 'react';
import Footer from "../components/Footer";


function Server(props) {
    let id = props.id;

    return (
        <>

            <Footer/>
        </>
    )

}

export default Server;